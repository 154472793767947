/*
 * @Author: ckz 1498632919@qq.com
 * @Date: 2023-03-01 10:19:33
 * @LastEditors: ckz 1498632919@qq.com
 * @LastEditTime: 2024-09-03 17:32:50
 * @FilePath: \ckz\vue-shenbao\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
const router = createRouter({
  history: createWebHashHistory(), // createWebHashHistory 为哈希模式的路由，如果需要选择 histiry 模式，可以用 createWebHistory 方法。
  routes: [
    {
      path: "/",
      component: () => import("@/views/index/index.vue"),
      children: [
        // // 主页
        {
          path: "/Home",
          name: "HomeList",
          title: "主页",
          component: () => import("@/views/Home/Home.vue"),
        },
        // 公司管理
        {
          path: "/Companies",
          name: "CompaniesList",
          title: "公司管理",
          component: () => import("@/views/Companies/Companies.vue"),
        },
        // 用户管理
        {
          path: "/Users",
          name: "UsersList",
          title: "用户管理",
          component: () => import("@/views/Users/Users.vue"),
        },
        // 工地管理
        {
          path: "/Works",
          name: "WorksList",
          title: "工地管理",
          component: () => import("@/views/Works/Works.vue"),
        },
        // 工地计划
        {
          path: "/Plans",
          name: "PlansList",
          title: "工地计划",
          isUnLook: true,
          component: () => import("@/views/Works/Plans/Plans.vue"),
        },
        // 装修日志
        {
          path: "/Logs",
          name: "LogsList",
          title: "装修日志",
          component: () => import("@/views/Logs/Logs.vue"),
        },
        // 商机管理
        {
          path: "/Chances",
          name: "ChancesList",
          title: "商机管理",
          component: () => import("@/views/Chances/Chances.vue"),
        },
        // 案例管理
        {
          path: "/Cases",
          name: "CasesList",
          title: "案例管理",
          component: () => import("@/views/Cases/Cases.vue"),
        },
        // 设备列表
        {
          path: "/Cameras",
          name: "CamerasList",
          title: "设备列表",
          component: () => import("@/views/Cameras/Cameras.vue"),
        },

        // 保修卡
        {
          path: "/Cards",
          name: "CardsList",
          title: "保修卡",
          component: () => import("@/views/Cards/Cards.vue"),
        },
        // 评论管理
        {
          path: "/Comments",
          name: "CommentsList",
          title: "评论管理",
          component: () => import("@/views/Comments/Comments.vue"),
        },
        // 计划模板
        {
          path: "/Plan-Templates",
          name: "Plan-TemplatesList",
          title: "计划模板",
          component: () => import("@/views/Plan-Templates/Plan-Templates.vue"),
        },


        //材料/预算
        {
          path: "/budget",
          name: "budget",
          title: "材料/预算",
          children: [
            //材料库
            {
              path: "/material",
              name: "material",
              title: "材料库",
              component: () => import("@/views/budget/material/material.vue"),
            },
            //工人库
            {
              path: "/workerBank",
              name: "workerBank",
              title: "工人库",
              component: () =>
                import("@/views/budget/workerBank/workerBank.vue"),
            },
              //基础单位
              {
                  path: "/Base-Unit",
                  name: "Base-Unit",
                  title: "基础单位",
                  component: () => import("@/views/budget/Base-Unit/Base-Unit.vue"),
              },
              //商品类型
              {
                  path: "/Goods-Type",
                  name: "Goods-Type",
                  title: "商品类型",
                  component: () => import("@/views/budget/Goods-Type/Goods-Type.vue"),
              },
              //工程定额
              {
                  path: "/Project-Quota",
                  name: "Project-Quota",
                  title: "工程定额",
                  component: () => import("@/views/budget/Project-Quota/Project-Quota.vue"),
              },
              // 预算模板
              {
                  path: "/Budgets",
                  name: "BudgetsList",
                  title: "预算模板",
                  component: () => import("@/views/budget/Budgets/Budgets.vue"),
              },
          ],
        },

        //材料/预算
        {
          path: "/marketing",
          name: "marketing",
          title: "营销管理",
          children: [
              // 礼品
              {
                  path: "/gifts",
                  name: "giftsList",
                  title: "礼品",
                  component: () => import("@/views/marketing/gifts/gifts.vue"),
              },
              // 优惠卷
              {
                path: "/coupons",
                name: "couponsList",
                title: "优惠卷",
                component: () => import("@/views/marketing/coupons/coupons.vue"),
            }, 
            // 核销管理
            {
              path: "/UserCoupons",
              name: "UserCouponsList",
              title: "核销管理",
              component: () => import("@/views/marketing/UserCoupons/UserCoupons.vue"),
          },
          ],
        },

        //系统
        {
          path: "/auth",
          name: "authList",
          title: "系统",
          children: [
            //管理员
            {
              path: "/user",
              name: "user",
              title: "管理员",
              component: () => import("@/views/auth/user/user.vue"),
            },

              //设计师
              {
                path: "/stylist",
                name: "stylist",
                title: "设计师",
                component: () => import("@/views/auth/stylist/stylist.vue"),
              },
            

          ],
        },
          
      ],
    },

    {
      path: "/login",
      name: "loginList",
      component: () => import("@/views/login/login.vue"),
    },
  ],
});


// 全局前置守卫
router.beforeEach((to, from, next) => {

    console.log(to,'to')
    // 检查用户是否已登录
    // const isUserLoggedIn =  localStorage.getItem('user') ? true : false;

    // if (!isUserLoggedIn && to.path !== '/login') {
    //   // 如果用户未登录，重定向到登录页面
    //   next('/login');
    // } else if (isUserLoggedIn && to.path === '/login') {
    //   // 如果用户已登录，并且访问的是登录页面，则重定向到主页
    //   next('/login');
    // } else {
    console.log(router.getRoutes(),'11111111111111111111111');
    next();
    // }
});


export default router;
